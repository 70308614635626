import React from 'react'
import { Container, Box, Image } from 'theme-ui'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Features from '@solid-ui-blocks/Educabot/Landings/Block05'

import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import ThanksMessage from '../../../../solid-ui/solid-ui-blocks/src/Educabot/ThanksMessage/ThanksMessage'
import favicon from '../../../../../site/content/assets/favicon.png'

const styles = {
  thanks: {
    background: '#FFFFFF',
    pt: '15px'
  }
}

const Thanks = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* <Header content={content['header']} /> */}

      <Box sx={styles.thanks}>
        <ThanksMessage />
      </Box>
      <Features content={content['features-solution']} />
    </Layout>
  )
}

export const query = graphql`
  query tanksSmartTeamBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/thanks"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Thanks
